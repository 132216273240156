<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'frais-scolaire-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter frais scolaire</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="fraisScolaire"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'total_ttc'">
          <b-badge :variant="active(props.row.total_ttc)">
            {{ props.row.total_ttc }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'montant_tva'">
          <b-badge :variant="active(props.row.montant_tva)">
            {{ props.row.montant_tva }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'frais-scolaire-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteFraisScolaire(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip, BBadge,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    BBadge,
  },
  data() {
    return {
      user: {},
      load: 'false',
      pageLength: 10,
      columns: [
        {
          label: 'Niveau',
          field: 'level',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher level',
          },
        },
        {
          label: 'Filiére',
          field: 'parcour',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Filiére',
          },
        },

        {
          label: 'Frais Inscrit TND',
          field: 'inscription_costs',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Frais Inscrit',
          },
        },
        {
          label: 'Droits Scolaire TND',
          field: 'school_fees',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Droits Scolaire',
          },
        },
        {
          label: 'TVA %',
          field: 'vat',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher TVA',
          },
        },
        {
          label: 'Montant TVA TND',
          field: 'montant_tva',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Montant TVA',
          },
        },
        {
          label: 'Total TTC TND',
          field: 'total_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Total TCC',
          },
        },
        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      fraisScolaire: [],
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      rm: '',

    }
  },
  computed: {
    active() {
      const statusColor = 'light-success'

      return status => statusColor[status]
    },

  },
  created() {
    this.getFraisScolaire()
    this.user = storeAuth.state.user
  },
  methods: {

    async getFraisScolaire() {
      this.load = 'true'
      const response = await axios.get('/api/school-fees/', { params: { academic_year_id: storeAuth.state.currentAcademicYear.id } })
      this.fraisScolaire = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    deleteFiliere(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/school-fees/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getFraisScolaire()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Frais Scolaire supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Frais Scolaire Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
